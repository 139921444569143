$(document).foundation();

$(document).ready(function(){


	/* circle charts */
	$('.circle-chart').easyPieChart({
		barColor: '#ffd500',
		trackColor: '#EDEFF1',
		lineWidth: 5,
		size: 90,
		animate: 1000,
		scaleColor: false
	});


	/* nav toggle */
	$('.nav-toggle').click(function(){
		
		if ($(window).width() < 1024) {	
			$('body').toggleClass('open');
			$('.sites-pullup').removeClass('open');
		}
	});


	/* hide sidebar if a user clicks on the dark overlay */
	$(document).click(function (e){ // hiding the off canvas menus
		var sideBar = $('.sidebar');
		if (!sideBar.is(e.target) && sideBar.has(e.target).length === 0) {
			$('body').removeClass('open');
			$('.sites-pullup').removeClass('open');
			$('body').removeClass('overlay');
		}
	});


	// /* menu overlays */
	// $('.nav-main a').click(function(){

	// 	if($(this).parents('li').hasClass('inactive')) {
	// 		$('.nav-main ul li.inline-active a').trigger('click');
	// 		return false;
	// 	}

	// 	if($(this).hasClass('overlay')) {
			
	// 		if($(this).parents('li').hasClass('inline-active')) {
	// 			$(this).parents('li').removeClass('inline-active');
	// 			$('.nav-sub.inline-active').removeClass('inline-active').addClass('hidden');
	// 			$('.nav-sub.inactive').removeClass('inactive');
	// 			$('.nav-main ul li').removeClass('active');
	// 			$('.nav-main ul li').filter(function(){
	// 				if ($(this).hasClass('inactive')) {
	// 					$(this).removeClass('inactive');
	// 					$(this).addClass('active');
	// 				}
	// 			});
	// 			$('.wrap').removeClass('inline-active');
	// 			return false;
	// 		}

	// 		else {

	// 			var $menuID = $(this).attr('data-menu-id');
	// 			$('.nav-sub').filter(function(){
	// 			    if ($(this).attr('data-menu-overlay') == $menuID) {
				        
	// 			        $(this).removeClass('hidden inactive').addClass('inline-active');
				        
	// 			        $('.nav-main ul li').filter(function(){
	// 			        	if ($(this).hasClass('active')) {
	// 			        		$(this).addClass('inactive');
	// 			        		$(this).removeClass('active');
	// 			        	}
	// 			        });

	// 			    }
	// 			});
	// 			$(this).parents('li').addClass('active inline-active');
	// 			$('.wrap').addClass('inline-active');
	// 			return false;

	// 		}
	// 	}

	// });

	

	/* responsive tables */
	var switched = false;
	var updateTables = function() {
		if (($(window).width() < 767) && !switched ){
			switched = true;
			$("table.responsive").each(function(i, element) {
				splitTable($(element));
			});
			return true;
		}
		else if (switched && ($(window).width() > 767)) {
			switched = false;
			$("table.responsive").each(function(i, element) {
				unsplitTable($(element));
			});
		}
	};



	/* select focus */
	$('select').focus(function() {
		$(this).parents('.select-container').addClass('focus');
	});

	$('select').blur(function() {
		$(this).parents('.select-container').removeClass('focus');
	});


	/* focus username input on the login page */
	if ($('input.focus-on-load').length) {
		$('input.focus-on-load').focus();
	}


	/* page settings - show/hide expanded options for pagespeed */
	if ($('.sp-options').length) {
		$('.switch').filter(function(){ // on document ready
			if ($(this).children('input').prop('checked')) {
				$(this).parents('.sp-header').siblings('.sp-options').show();
			}
		});
	}

	$('.switch').click(function(){ // on switch click
		if ($(this).children('input').prop('checked')) {
			$(this).parents('.sp-header').siblings('.sp-options').slideDown('250');
		}
		else {
			$(this).parents('.sp-header').siblings('.sp-options').slideUp('250');
		}
	});
	
	

	$(window).load(updateTables);
	$(window).on("redraw",function(){switched=false;updateTables();}); // An event to listen for
	$(window).on("resize", updateTables);

	function splitTable(original) {
		original.wrap("<div class='table-wrapper' />");
		
		var copy = original.clone();
		copy.find("td:not(:first-child), th:not(:first-child)").css("display", "none");
		copy.removeClass("responsive");
		
		original.closest(".table-wrapper").append(copy);
		copy.wrap("<div class='pinned' />");
		original.wrap("<div class='scrollable' />");

	setCellHeights(original, copy);
	}

	function unsplitTable(original) {
	original.closest(".table-wrapper").find(".pinned").remove();
	original.unwrap();
	original.unwrap();
	}

	function setCellHeights(original, copy) {
	var tr = original.find('tr'),
		tr_copy = copy.find('tr'),
		heights = [];

	tr.each(function (index) {
	  var self = $(this),
		  tx = self.find('th, td');

	  tx.each(function () {
		var height = $(this).outerHeight(true);
		heights[index] = heights[index] || 0;
		if (height > heights[index]) heights[index] = height;
	  });

	});

	tr_copy.each(function (index) {
	  $(this).height(heights[index]);
	});
	}


});